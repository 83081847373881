
// export const baseUrl = 'http://localhost:3000';
//export const baseUrl = 'https://api.web-modern.ir/';
export const baseUrl = 'https://api.118asansori.com/';
//export const baseUrl = 'https://api.imenforoush.com/';

export const getApiUrl = (url: string) => {
    return `${baseUrl}/api${url}`;
}

export const headerConfig = () => {
    const token = localStorage.getItem('token');
    return {
        headers: {
            'x-security-token':
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJNYWhkaUAyMzk3NCIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTY3OTIwNjU5MX0.ElNT4XhaO7KfO0J4yxKIzU0UY1_eUvUZLk3qBOYQEFE',
            'x-auth-token': token
        }
    }
}

export const quiil = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],        
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: true,
        }
    },


    formats: [
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]
}
